













import { Vue } from 'nuxt-property-decorator'

const DefaultHeader = () => import('~/components/organisms/Header.vue')
const Footer = () => import('~/components/organisms/Footer.vue')
const UserProfileRegistration = () => import('~/components/auth/UserProfileRegistration.vue')

export default Vue.extend({
  components: {
    DefaultHeader,
    Footer,
    UserProfileRegistration,
  },
  data() {
    return {}
  },
  methods: {},
})

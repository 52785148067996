/* eslint-disable import/no-mutable-exports */
import { NuxtAxiosInstance } from '@nuxtjs/axios'

let $axios: NuxtAxiosInstance

export function initializeAxios(axiosInstance: NuxtAxiosInstance): void {
  $axios = axiosInstance
  $axios.setHeader('Accept', 'text/plain, */*')
  $axios.setHeader('Content-Type', 'application/x-www-form-urlencoded', ['post'])
}

export { $axios }

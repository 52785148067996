import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import Vuex from 'vuex'
import { $axios } from '~/utils/api'
import { AuthUser, PaymentMethod, Invoice, IsAuth, ServerResponseAuthUser, ServerResponseLoginUser, ServerResponseSessions } from '~/@types/authUser'
const store = new Vuex.Store<any>({})

export interface SigninState {
  authUser: AuthUser | null
  isAuth: boolean
  isProfileRegistered: boolean
  isLoading: boolean
  prefectures: Array<any>
  paymentMethod: PaymentMethod | null
}

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'authUser',
  store,
  dynamic: true,
})
export default class Signin extends VuexModule implements SigninState {
  authUser: AuthUser | null = null
  isAuth: boolean = false
  isProfileRegistered: boolean = false
  isLoading: boolean = false
  prefectures: Array<any> = []
  paymentMethod: PaymentMethod | null = null
  invoices: Array<Invoice> | null = null

  @Mutation
  AUTHED_USER(data: IsAuth) {
    this.isAuth = data.is_logged_in
    this.isProfileRegistered = data.is_profile_registered
  }

  @Mutation
  LOGOUT_USER(data: boolean) {
    this.isAuth = data
    this.isProfileRegistered = false
  }

  @Mutation
  AUTH_USER(data: AuthUser | null) {
    this.authUser = data
  }

  @Mutation
  PAYMENT_METHOD(data: any | null) {
    if (data == null) {
      this.paymentMethod = null
      return
    }
    this.paymentMethod = data.stripe_payment_method
  }

  @Mutation
  INVOICES(data: any | null) {
    if (data == null) {
      this.paymentMethod = null
      return
    }
    this.invoices = data.invoices
  }

  @Mutation
  LOADING(data: boolean) {
    this.isLoading = data
  }

  @Mutation
  GET_PREFECTURES(data: any) {
    this.prefectures = data.prefectures
  }

  @Action({ rawError: true, commit: 'GET_PREFECTURES' })
  async getPrefectures() {
    try {
      const apiBaseUrl = process.env.API_BASE_URL ? encodeURI(process.env.API_BASE_URL + 'prefectures') : ''
      const response = await $axios.get(apiBaseUrl)
      return response.data
    } catch (e) {
      console.log('error')
      throw e
    }
  }

  @Action({ rawError: true, commit: 'LOGOUT_USER' })
  async logout() {
    try {
      const apiBaseUrl = process.env.API_BASE_URL ? encodeURI(process.env.API_BASE_URL + 'users/sign_out') : ''
      const response = await $axios
        .delete(apiBaseUrl, {
          withCredentials: true,
        })
        .then(() => {
          return false
        })
      return response
    } catch (e) {
      console.log('error')
      throw e
    }
  }

  // ログイン
  @Action({ rawError: true })
  async login({ email, password }: { email: string; password: string }) {
    try {
      const apiBaseUrl = process.env.API_BASE_URL ? encodeURI(process.env.API_BASE_URL + 'users/sign_in') : ''
      const params = {
        email,
        password,
      }
      await $axios
        .post(apiBaseUrl, params, { withCredentials: true })
        .then((res: ServerResponseLoginUser) => {
          if (res.data.error) {
            throw new Error(res.data.error)
          }
          return res
        })
        .catch((error: string) => {
          console.log(error)
          throw error
        })
    } catch (e) {
      console.log(e)
      throw e
    }
  }

  // ユーザ登録
  @Action({ rawError: true })
  async signUp({ email, name, password, passwordConfirmation }: { email: string; name: string; password: string; passwordConfirmation: string }) {
    try {
      const apiBaseUrl = process.env.API_BASE_URL ? encodeURI(process.env.API_BASE_URL + 'users') : ''
      const params = {
        email,
        name,
        password,
        password_confirmation: passwordConfirmation,
      }
      const response = await $axios.post(apiBaseUrl, params, {
        withCredentials: true,
      })
      console.log(response)
      return response
    } catch (e) {
      console.log(e)
      console.log('error')
      throw new Error('メールアドレスが重複しているか、入力に誤りがあります')
    }
  }

  // ユーザ情報取得
  @Action
  async getUser() {
    try {
      const apiBaseUrl = process.env.API_BASE_URL ? encodeURI(process.env.API_BASE_URL + 'users/me') : ''

      await $axios
        .get(apiBaseUrl, {
          withCredentials: true,
        })
        .then((res: ServerResponseAuthUser) => {
          this.AUTH_USER(res.data)
        })
        .catch((error: string) => {
          console.log(error)
          this.AUTH_USER(null)
        })
    } catch (e) {
      console.log(e)
      this.AUTH_USER(null)
      throw e
    }
  }

  // 登録済みクレジットカード情報取得
  @Action({ rawError: true })
  async getPaymentMethod() {
    if (this.authUser === null) {
      return
    }
    const userId = this.authUser!!.id
    if (userId === null) {
      return
    }

    try {
      const apiBaseUrl = process.env.API_BASE_URL ? encodeURI(process.env.API_BASE_URL + `users/${userId}/payment_method`) : ''

      await $axios
        .get(apiBaseUrl, {
          withCredentials: true,
        })
        .then((res: ServerResponseAuthUser) => {
          this.PAYMENT_METHOD(res.data)
        })
        .catch((error: string) => {
          console.log(error)
          this.PAYMENT_METHOD(null)
        })
    } catch (e) {
      console.log(e)
      this.PAYMENT_METHOD(null)
      throw e
    }
  }

  // ログイン状態を確認する
  @Action
  async getSessions() {
    try {
      const apiBaseUrl = process.env.API_BASE_URL ? encodeURI(process.env.API_BASE_URL + 'sessions') : ''
      await $axios
        .get(apiBaseUrl, {
          withCredentials: true,
        })
        .then((res: ServerResponseSessions) => {
          this.AUTHED_USER(res.data)
        })
        .catch((error: any) => {
          console.log(error)
          this.AUTHED_USER({
            is_logged_in: false,
            is_profile_registered: false,
          })
        })
      this.LOADING(true)
    } catch (e) {
      console.log('error')
      throw e
    }
  }

  @Action({ rawError: true })
  async updateProfile({ id, params }: { id: number; params: {} }) {
    try {
      const apiBaseUrl = process.env.API_BASE_URL ? encodeURI(process.env.API_BASE_URL + `users/${id}`) : ''

      return await $axios
        .patch(apiBaseUrl, params, { withCredentials: true })
        .then((res: any) => {
          this.getUser()
          return res.status === 200
        })
        .catch((e) => {
          console.log(e)
          throw e
        })
    } catch (e) {
      console.log(e)
      throw e
    }
  }

  @Action({ rawError: true })
  async updatePassword({ id, currentPassword, password, passwordConfirmation }: { id: number; currentPassword: String; password: String; passwordConfirmation: String }) {
    try {
      const apiBaseUrl = encodeURI(process.env.API_BASE_URL + `users/${id}/update_password`)

      const params = {
        password,
        current_password: currentPassword,
        password_confirmation: passwordConfirmation,
      }

      const result = await $axios
        .patch(apiBaseUrl, params, { withCredentials: true })
        .then((res: any) => {
          return res.status === 200
        })
        .catch((e) => {
          console.log(e)
          return false
        })
      return result
    } catch (e) {
      console.log(e)
      return false
    }
  }

  @Action({ rawError: true })
  async updateMail({ id, params }: { id: number; params: {} }) {
    try {
      const apiBaseUrl = encodeURI(process.env.API_BASE_URL + `users/${id}/update_mail`)

      console.log(params)

      const result = await $axios
        .patch(apiBaseUrl, params, { withCredentials: true })
        .then((res: any) => {
          return res.status === 200
        })
        .catch((e) => {
          console.log(e)
          return false
        })
      return result
    } catch (e) {
      console.log(e)
      return false
    }
  }

  @Action({ rawError: true })
  async resetPassword(params: {}): Promise<{}> {
    try {
      const apiBaseUrl = encodeURI(process.env.API_BASE_URL + '/users/passwords/password_reset')

      const result = await $axios
        .post(apiBaseUrl, params, { withCredentials: true })
        .then((res: any) => {
          return res.status === 200
        })
        .catch((e) => {
          console.log(e)
          return false
        })
      return result
    } catch (e) {
      console.log(e)
      return false
    }
  }

  @Action({ rawError: true })
  async updateResetPassword(params: {}): Promise<{}> {
    try {
      const apiBaseUrl = encodeURI(process.env.API_BASE_URL + 'users/passwords/update_reset_password')

      const result = await $axios
        .put(apiBaseUrl, params, { withCredentials: true })
        .then((res: any) => {
          return res.status === 200
        })
        .catch((e) => {
          console.log(e)
          return false
        })
      return result
    } catch (e) {
      console.log(e)
      return false
    }
  }

  @Action({ rawError: true })
  async updatePaymentMethod(paymentMethodId: string) {
    try {
      const uid = this.authUser!!.id
      const url = encodeURI(`${process.env.API_BASE_URL}users/${uid}/payment_method`)

      const params = {
        payment_method_id: paymentMethodId,
      }
      await $axios
        .patch(url, params, { withCredentials: true })
        .then(() => {
          this.getUser()
        })
        .catch(() => {
          throw new Error('クレジットカード情報変更処理でエラーが発生しました。')
        })
    } catch (e) {
      console.log(e)
      throw e
    }
  }

  @Action({ rawError: true })
  async getInvoices() {
    try {
      const url = encodeURI(`${process.env.API_BASE_URL}payments/invoices`)
      return await $axios
        .get(url, { withCredentials: true })
        .then((res) => {
          this.INVOICES(res.data)
        })
        .catch((e) => {
          console.log(e)
          this.INVOICES(null)
        })
    } catch (e) {
      console.log(e)
      this.INVOICES(null)
      throw e
    }
  }
}
